

















































































import { PendingCriterionData } from '@/jbi-shared/types/criterions.types';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { validateExistingCriterionLinkedBpr } from '@/jbi-shared/util/cplus-criterion.util';
import { opsToText } from '@/utils/quill-delta.util';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class StagingCriterionFormStepOne extends mixins(ViewModeMixin) {
  @Prop()
  public existingCriterions!: FullDocumentRevisionObject['revision']['sections']['criterionSection'];
  @Prop()
  public validCriterions!: PendingCriterionData[];
  @Prop()
  public newlyAddedCriterions!: PendingCriterionData[];
  @Prop()
  public dirtyCriterions!: PendingCriterionData[];
  @Prop()
  public dirtyBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public invalidCriterions!: PendingCriterionData[];
  @Prop()
  public selectedCriterions!: PendingCriterionData[];

  get proxySelectedCriterions() {
    return this.selectedCriterions;
  }
  set proxySelectedCriterions(value) {
    this.$emit('update:selectedCriterions', value);
  }

  public getCriterionIndex(criterion: PendingCriterionData): number {
    return this.dirtyCriterions.findIndex(
      (dirtyCriterion) =>
        JSON.stringify(dirtyCriterion) === JSON.stringify(criterion)
    );
  }

  public formatCriterion(criterion: PendingCriterionData) {
    return criterion.content.title;
  }

  /*
   * Returns true if the existingCriterion does not exist in the dirtyCriterion list.
   * - This is used to indicate whether a previous criterion has been removed in
   *   the pending document.
   */
  public isRemovedCriterion(existingCriterion: PendingCriterionData) {
    return !this.dirtyCriterions.find((criterion) => {
      return JSON.stringify(criterion) === JSON.stringify(existingCriterion);
    });
  }

  public isInvalidCriterion(criterion: PendingCriterionData) {
    return !criterion.isValid;
  }

  public isMissingLinkedBpr(criterion: PendingCriterionData) {
    return (
      criterion.isValid &&
      !validateExistingCriterionLinkedBpr(
        criterion as FullDocumentRevisionObject['revision']['sections']['criterionSection'][0],
        this.dirtyBprs
      )
    );
  }
}
