var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('div',{staticClass:"modal-card has-modal-fullscreen"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.modalTitle)+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[_c('div',{class:_vm.$style.bodyContainer},[_c('div',{class:_vm.$style.backContainer},[_c('b-button',{attrs:{"inverted":"","type":"is-primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Back ")])],1),_c('div',{class:_vm.$style.bodyContent},[_c('b-field',{attrs:{"label":"Tags"}},[_c('TagsEditor',{attrs:{"entityId":_vm.criterionId,"entityType":_vm.TagEntityTypeEnum.criterion},model:{value:(_vm.criterionFormDirtyTagMaps),callback:function ($$v) {_vm.criterionFormDirtyTagMaps=$$v},expression:"criterionFormDirtyTagMaps"}})],1),_c('ValidationProvider',{attrs:{"rules":"required","name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Type","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('b-select',{model:{value:(_vm.dirtyCriterion.content.type),callback:function ($$v) {_vm.$set(_vm.dirtyCriterion.content, "type", $$v)},expression:"dirtyCriterion.content.type"}},_vm._l((_vm.options),function(option){return _c('option',{key:option.id,domProps:{"value":option.id}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)]}}],null,true)}),(_vm.dirtyCriterion.content.type === _vm.CRITERION_TYPE.BOOLEAN)?_c('BooleanCriterionInput',{attrs:{"options":_vm.dirtyCriterion.content.booleanOptions},on:{"update:options":function($event){return _vm.$set(_vm.dirtyCriterion.content, "booleanOptions", $event)}}}):_vm._e(),(_vm.dirtyCriterion.content.type === _vm.CRITERION_TYPE.RANGE)?_c('RangeCriterionInput',{attrs:{"range":_vm.dirtyCriterion.content.range},on:{"update:range":function($event){return _vm.$set(_vm.dirtyCriterion.content, "range", $event)}}}):_vm._e(),(_vm.dirtyCriterion.content.type === _vm.CRITERION_TYPE.CHECKBOXES)?_c('CheckboxesCriterionInput',{attrs:{"options":_vm.dirtyCriterion.content.checkboxesOptions},on:{"update:options":function($event){return _vm.$set(_vm.dirtyCriterion.content, "checkboxesOptions", $event)}}}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required","name":"Criterion Inquiry"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Criterion Inquiry","type":errors.length ? 'is-danger' : undefined,"message":errors[0]}},[_c('b-input',{model:{value:(_vm.dirtyCriterion.content.title),callback:function ($$v) {_vm.$set(_vm.dirtyCriterion.content, "title", $$v)},expression:"dirtyCriterion.content.title"}})],1)]}}],null,true)}),_c('b-field',{class:_vm.$style.linkedBprContainer,attrs:{"data-test":"project-type-selector","type":_vm.errors.length ? 'is-danger' : undefined,"message":_vm.errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Linked Best Practice Recommendation "),_vm._l((_vm.missingLinkedBprs),function(missingLinkedBpr,index){return _c('div',{key:'missing-linked-bpr-' + index,class:_vm.$style.missingLinkedBpr},[_vm._v(" "+_vm._s(missingLinkedBpr.name)+" ")])})]},proxy:true}],null,true)},[_c('BaseMultiSelect',{class:_vm.$style.multiselect,attrs:{"options":_vm.bprOptions,"placeholder":"Select Best Practice Recommendations","maxHeight":200},model:{value:(_vm.criterionLinkedBprs),callback:function ($$v) {_vm.criterionLinkedBprs=$$v},expression:"criterionLinkedBprs"}})],1)],1)])]),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{class:_vm.$style.footerContent},[_c('button',{staticClass:"button is-primary",attrs:{"disabled":validator.invalid},on:{"click":function($event){validator.validate().then(function (validated) {
              if (validated === false) { return; }
              _vm.editCriterion();
            })}}},[_vm._v(" Edit Criterion ")])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }