





























































































import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { mixins } from 'vue-class-component';
import { Component, Prop, Provide } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import StagingCriterionFormStepOne from './StagingCriterionFormStepOne.vue';
import StagingCriterionFormStepTwo from './StagingCriterionFormStepTwo.vue';
import { PendingCriterionData } from '@/jbi-shared/types/criterions.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    StagingCriterionFormStepOne,
    StagingCriterionFormStepTwo
  }
})
export default class StagingCriterionForm extends mixins(ViewModeMixin) {
  @Prop()
  public existingCriterions!: FullDocumentRevisionObject['revision']['sections']['criterionSection'];
  @Prop()
  public existingBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public validCriterions!: PendingCriterionData[];
  @Prop()
  public invalidCriterions!: PendingCriterionData[];
  @Prop()
  public dirtyCriterions!: PendingCriterionData[];
  @Prop()
  public dirtyBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public newlyAddedCriterions!: PendingCriterionData[];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  public criterionFormDirtyTagMaps =
    JSON.parse(JSON.stringify(this.dirtyTagMaps)) || [];

  public selectedCriterions: PendingCriterionData[] = [];
  public activeStep = 1;

  get hasInvalidSelectedCriterions() {
    const invalidCriterions = this.selectedCriterions.filter((criterion) => {
      return !criterion.isValid;
    });
    return invalidCriterions.length > 0;
  }

  public nextStep() {
    if (this.activeStep < 2) {
      this.activeStep += 1;
    }
  }

  public prevStep() {
    if (this.activeStep > 1) {
      this.activeStep -= 1;
    }
  }

  public saveCriterions() {
    this.$emit('update:criterions', this.selectedCriterions);
    this.$emit('update:dirtyTagMaps', this.criterionFormDirtyTagMaps);
    this.$emit('close');
  }

  public autoSelectIdenticalPreviousCriterion() {
    const remainder = this.dirtyCriterions.filter((criterion) => {
      return !this.newlyAddedCriterions.includes(criterion);
    });
    this.selectedCriterions = this.selectedCriterions.concat(remainder);
  }

  public mounted() {
    this.autoSelectIdenticalPreviousCriterion();
  }
}
