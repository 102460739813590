











































import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import StagingCriterionFormCard from './StagingCriterionFormCard.vue';
import { generateDraftCriterion } from '@/jbi-shared/util/cplus-criterion.util';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import StagingCriterionFormEditModal from './StagingCriterionFormEditModal.vue';
import { PendingCriterionData } from '@/jbi-shared/types/criterions.types';
import { PendingBprData } from '@/jbi-shared/types/cplus-bpr.types';
import draggable from 'vuedraggable';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    draggable,
    StagingCriterionFormCard
  }
})
export default class StagingCriterionFormStepTwo extends mixins(ViewModeMixin) {
  @Prop()
  public selectedCriterions!: PendingCriterionData[];
  @Prop()
  public existingBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public dirtyBprs!: PendingBprData[];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];

  get proxySelectedCriterions() {
    return this.selectedCriterions;
  }

  set proxySelectedCriterions(value) {
    this.$emit('update:selectedCriterions', value);
  }

  public openAddCriterionModal() {
    this.$buefy.modal.open({
      parent: this,
      component: StagingCriterionFormEditModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Add Audit Criteria',
        dirtyTagMaps: this.dirtyTagMaps
      },
      events: {
        'update:criterion': this.addNewCriterion,
        'update:dirtyTagMaps': (e: DirtyTagMap[]) =>
          this.$emit('update:dirtyTagMaps', e)
      }
    });
  }

  public addNewCriterion(criterion: PendingCriterionData) {
    const updatedCriterions = [...this.proxySelectedCriterions, criterion];
    this.proxySelectedCriterions = updatedCriterions;
  }

  public removeCriterion(i: number) {
    const updatedCriterions = this.proxySelectedCriterions.filter(
      (_, j) => j !== i
    );
    this.proxySelectedCriterions = updatedCriterions;
  }

  public isInvalidCriterion(criterion: PendingCriterionData) {
    return criterion.hasOwnProperty('isValid') && criterion.isValid === false;
  }
}
