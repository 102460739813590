


























































































import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { opsToText } from '@/utils/quill-delta.util';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import { mixins } from 'vue-class-component';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import {
  generateDraftCriterion,
  validateExistingCriterionLinkedBpr
} from '@/jbi-shared/util/cplus-criterion.util';
import {
  BooleanCriterionContent,
  CheckboxesCriterionContent,
  CRITERION_TYPE,
  PendingCriterionData,
  RangeCriterionContent
} from '@/jbi-shared/types/criterions.types';
import { StringInputOption } from '@/jbi-shared/types/form.types';
import CriterionForm from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm.vue';
import StagingCriterionFormEditModal from './StagingCriterionFormEditModal.vue';
import { PendingBprData } from '@/jbi-shared/types/cplus-bpr.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';

@Component({
  components: {
    ValidationProvider
  }
})
export default class StagingCriterionFormCard extends mixins(ViewModeMixin) {
  @Prop({
    default() {
      return generateDraftCriterion(0);
    }
  })
  public value!: PendingCriterionData;
  @Prop()
  public existingBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public dirtyBprs!: PendingBprData[];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public index!: number;

  public openEditModal() {
    this.$buefy.modal.open({
      parent: this,
      component: StagingCriterionFormEditModal,
      hasModalCard: true,
      fullScreen: true,
      trapFocus: true,
      props: {
        modalTitle: 'Edit Audit Criteria',
        criterion: this.value,
        dirtyBprs: this.dirtyBprs,
        dirtyTagMaps: this.dirtyTagMaps,
        missingLinkedBprs: this.missingLinkedBprs
      },
      events: {
        'update:criterion': (e: PendingCriterionData) =>
          this.$emit('update:criterion', e),
        'update:dirtyTagMaps': (e: DirtyTagMap[]) =>
          this.$emit('update:dirtyTagMaps', e)
      }
    });
  }

  get isInvalidCriterion() {
    return this.value.hasOwnProperty('isValid') && this.value.isValid === false;
  }

  get hasCriterionType() {
    return this.value.content.hasOwnProperty('type');
  }

  get criterionOptions() {
    if (!this.hasCriterionType) {
      return [];
    }
    switch (this.value.content.type) {
      case CRITERION_TYPE.BOOLEAN:
        return (this.value.content as BooleanCriterionContent).booleanOptions;
      case CRITERION_TYPE.RANGE:
        return (this.value.content as RangeCriterionContent).range;
      case CRITERION_TYPE.CHECKBOXES:
        return (this.value.content as CheckboxesCriterionContent)
          .checkboxesOptions;
    }
  }

  get isBooleanType() {
    return this.value.content.type === CRITERION_TYPE.BOOLEAN;
  }

  get isRangeType() {
    return this.value.content.type === CRITERION_TYPE.RANGE;
  }

  get isCheckboxesType() {
    return this.value.content.type === CRITERION_TYPE.CHECKBOXES;
  }

  get existingBprOptions(): StringInputOption[] {
    return this.existingBprs.map((ss) => ({
      id: String(ss.bprSubSectionId || ss.tempId!),
      name: opsToText(ss.content || [])
    }));
  }

  get pendingBprOptions(): StringInputOption[] {
    return this.dirtyBprs.map((ss) => ({
      id: String(ss.bprSubSectionId || ss.tempId!),
      name: opsToText(ss.content || [])
    }));
  }

  get missingLinkedBprs() {
    return this.linkedBprs.filter((linkedBpr) => linkedBpr.missingLink);
  }

  get linkedBprs() {
    const linkedBprs = this.value.bprs;
    if (!linkedBprs) {
      return [];
    }

    return linkedBprs.map((linkedBpr) => {
      /* We first try to retrieve the BPR from the pending document's BPR */
      const pendingLinkedBpr = this.pendingBprOptions.find(
        ({ id }) =>
          String(linkedBpr.bprSubSectionId) === String(id) ||
          String(linkedBpr.tempId) === String(id)
      );
      if (pendingLinkedBpr) {
        return {
          ...pendingLinkedBpr,
          missingLink: false
        };
      }

      /* If this bpr has content property, it means that this linked bpr came
         from the pending document, but the bpr got removed in the bpr section.
         In this case, we want to display the value of the linked BPR, but display
         that it no longer exists in the pending document list. */
      if (linkedBpr.content) {
        return {
          id: String(linkedBpr.bprSubSectionId || linkedBpr.tempId),
          name: opsToText(linkedBpr.content || []),
          missingLink: true
        };
      }

      /* If we dont find it in the pending BPR list, then we will retrieve the BPR
           from the previous document's BPR list.
           In this case, we will flag this bpr option as a missing linked BPR since
           it does not exist in the pending document's BPR list. */
      const existingLinkedBpr = this.existingBprOptions.find(
        ({ id }) =>
          String(linkedBpr.bprSubSectionId) === String(id) ||
          String(linkedBpr.tempId) === String(id)
      );
      return {
        ...existingLinkedBpr,
        missingLink: true
      };
    });
  }
}
