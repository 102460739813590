


























































































































import { Component, Prop, Watch, Vue, Inject } from 'vue-property-decorator';
import {
  CriterionContent,
  CRITERION_TYPE_DISPLAY_NAME,
  CRITERION_TYPE,
  RangeCriterionContent,
  BooleanCriterionContent,
  Criterion,
  CheckboxesCriterionContent,
  PendingCriterionData
} from '@/jbi-shared/types/criterions.types';
import { StringInputOption, InputOption } from '@/jbi-shared/types/form.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { set as _set } from 'lodash';
// tslint:disable-next-line
import RangeCriterionInput from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm/RangeCriterionInput.vue';
// tslint:disable-next-line
import BooleanCriterionInput from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm/BooleanCriterionInput.vue';
// tslint:disable-next-line
import CheckboxesCriterionInput from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionForm/CheckboxesCriterionInput.vue';
import { uniq as _uniq, isEqual as _isEqual, pick as _pick } from 'lodash';
import { DialogProgrammatic as Dialog } from 'buefy';
import BaseMultiSelect from '@/jbi-shared/vue-components/BaseMultiSelect.vue';
import { mixins } from 'vue-class-component';
import TagsEditor from '@/components/editor/SectionEditor/TagsEditor.vue';
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import { generateDraftCriterion } from '@/jbi-shared/util/cplus-criterion.util';
import { generateRandomId } from '@/jbi-shared/util/document.utils';
import { PendingBprData } from '@/jbi-shared/types/cplus-bpr.types';
import { opsToText } from '@/utils/quill-delta.util';
import { TagEntityTypeEnum } from '@/jbi-shared/types/document.types';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    RangeCriterionInput,
    BooleanCriterionInput,
    CheckboxesCriterionInput,
    BaseMultiSelect,
    TagsEditor
  }
})
export default class StagingCriterionFormEditModal extends mixins() {
  @Prop(String) public modalTitle!: string;

  @Prop({
    default() {
      return {
        ...generateDraftCriterion(0),
        isValid: false
      };
    }
  })
  public criterion!: PendingCriterionData;
  @Prop()
  public dirtyBprs!: PendingBprData[];
  @Prop()
  public missingLinkedBprs!: StringInputOption[];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  public dirtyCriterion: PendingCriterionData = JSON.parse(
    JSON.stringify(this.criterion)
  );
  public criterionFormDirtyTagMaps: DirtyTagMap[] =
    JSON.parse(JSON.stringify(this.dirtyTagMaps)) || [];

  get CRITERION_TYPE() {
    return CRITERION_TYPE;
  }

  get TagEntityTypeEnum() {
    return TagEntityTypeEnum;
  }

  get options(): StringInputOption[] {
    return Object.entries(CRITERION_TYPE_DISPLAY_NAME).map(([key, value]) => {
      return {
        id: key,
        name: value
      };
    });
  }

  get bprOptions(): StringInputOption[] {
    return this.dirtyBprs.map((bprs) => ({
      id: String(bprs.bprSubSectionId || bprs.tempId!),
      name: opsToText(bprs.content || [])
    }));
  }

  get criterionLinkedBprs(): StringInputOption[] {
    if (!this.dirtyCriterion.bprs) {
      return [];
    }
    return this.dirtyCriterion.bprs
      .map(
        (bpr) =>
          this.bprOptions.find(
            ({ id }) =>
              String(bpr.bprSubSectionId) === String(id) ||
              String(bpr.tempId) === String(id)
          )!
      )
      .filter(Boolean);
  }

  /*
   * Every criterion has a property 'bprs' that contains an array of
   * bpr id(s) that the criterion is linked to.
   *
   * When setting the linked BPR of a specific criterion, we need to
   * convert the bprOption value to its respective id (bprSubSectionId or tempId)
   * in order to signify its linkage.
   */
  set criterionLinkedBprs(values: StringInputOption[]) {
    this.dirtyCriterion.bprs = values
      .map(
        (o) =>
          this.dirtyBprs.find(
            ({ bprSubSectionId, tempId }) =>
              String(bprSubSectionId) === String(o.id) ||
              String(tempId) === String(o.id)
          )!
      )
      .filter(Boolean)
      .map((ss) => ({
        bprSubSectionId: ss.bprSubSectionId,
        tempId: ss.tempId
      }));
    this.dirtyCriterion = { ...this.dirtyCriterion };
  }

  public async editCriterion() {
    if (!(await this.validatePreSave())) {
      return false;
    }

    const updatedCriterion = this.sanitizeCriterion(this.dirtyCriterion);
    const updatedDirtyTagMaps = this.criterionFormDirtyTagMaps;
    this.$emit('update:criterion', updatedCriterion);
    this.$emit('update:dirtyTagMaps', updatedDirtyTagMaps);
    this.$emit('close');
  }

  public async validatePreSave() {
    // check duplicated checkboxed options
    if (this.dirtyCriterion.content instanceof CheckboxesCriterionContent) {
      const { checkboxesOptions } = this.dirtyCriterion.content;
      const sanitized = this.sanitizeCriterionCheckboxesOptions(
        checkboxesOptions
      );
      if (!_isEqual(checkboxesOptions, sanitized)) {
        const confirm = () =>
          new Promise((resolve) => {
            return Dialog.confirm({
              message: `Empty values and duplicated values will be removed.`,
              confirmText: 'Confirm',
              cancelText: 'Cancel',
              onConfirm() {
                resolve(true);
              },
              onCancel() {
                resolve(false);
              }
            });
          });
        if (!(await confirm())) {
          return false;
        }
      }
    }
    return true;
  }

  public sanitizeCriterion(criterion: PendingCriterionData) {
    if (criterion.content instanceof RangeCriterionContent) {
      criterion.content.range = this.sanitizeCriterionRangeOptions(
        criterion.content.range
      );
    } else if (criterion.content instanceof CheckboxesCriterionContent) {
      criterion.content.checkboxesOptions = this.sanitizeCriterionCheckboxesOptions(
        criterion.content.checkboxesOptions
      );
    }

    // If this criterion is unstructured, add missing properties with template data.
    if (!criterion.hasOwnProperty('criterionSubSectionId')) {
      criterion.criterionSubSectionId = 0;
      criterion.documentSectionId = 0;
    }

    return {
      ...criterion,
      isValid: true
    };
  }

  public sanitizeCriterionRangeOptions(
    rangeArr: [number, number]
  ): [number, number] {
    return [Number(rangeArr[0]), Number(rangeArr[1])];
  }

  public sanitizeCriterionCheckboxesOptions(arr: string[]) {
    arr = arr.map((s) => String(s).trim()).filter(Boolean);
    arr = _uniq(arr);
    return arr;
  }

  @isDifferent
  @isTruthy
  @Watch('dirtyCriterion.content.type')
  public onTypeChange(type: CRITERION_TYPE) {
    switch (type) {
      case CRITERION_TYPE.BOOLEAN:
        this.dirtyCriterion.content = new BooleanCriterionContent(
          this.dirtyCriterion.content
        );
        break;
      case CRITERION_TYPE.RANGE:
        this.dirtyCriterion.content = new RangeCriterionContent({
          ...this.dirtyCriterion.content
        });
        break;
      case CRITERION_TYPE.CHECKBOXES:
        this.dirtyCriterion.content = new CheckboxesCriterionContent({
          ...this.dirtyCriterion.content
        });
        break;
    }
  }

  get criterionId(): number | string {
    return this.criterion?.criterionSubSectionId! || this.criterion?.tempId!;
  }
}
