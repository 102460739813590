






















































import { Component, Vue, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import CriterionSectionValueEditor from '@/components/editor/SectionEditor/CriterionSectionEditor/CriterionSectionValueEditor.vue';
import { textToOps, opsToText } from '@/utils/quill-delta.util';
import Op from 'quill-delta/dist/Op';
import { cloneDeep } from 'lodash';
import { mixins } from 'vue-class-component';
import { ViewModeMixin } from '@/utils/viewMode.mixin';
import {
  CriterionContent,
  Criterion,
  PendingCriterionData
} from '@/jbi-shared/types/criterions.types';
import { validateExistingCriterionLinkedBpr } from '@/jbi-shared/util/cplus-criterion.util';

// tslint:disable-next-line
import { FullDocumentRevisionObject } from '@/jbi-shared/types/full-document-revision-object.types';
import { DirtyTagMap } from '@/store/modules/documents/types/documents.types';
import StagingCriterionForm from './StagingCriterionForm.vue';
import SectionError from '@/components/editor/SectionEditor/SectionError.vue';

@Component({
  components: {
    draggable,
    CriterionSectionValueEditor,
    StagingCriterionForm,
    SectionTitleEditor: () =>
      import('@/components/editor/SectionEditor/SectionTitleEditor.vue'),
    SectionError
  }
})
export default class StagingCriterionSectionEditor extends mixins(
  ViewModeMixin
) {
  public drag = false;

  @Prop()
  public dirtyBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public dirtyCriterions!: PendingCriterionData[];
  @Prop()
  public dirtyTagMaps!: DirtyTagMap[];
  @Prop()
  public criterionSectionId!: number;
  @Prop()
  public existingCriterions!: FullDocumentRevisionObject['revision']['sections']['criterionSection'];
  @Prop()
  public existingBprs!: FullDocumentRevisionObject['revision']['sections']['bprSection'];
  @Prop()
  public invalidCriterions!: PendingCriterionData[];
  @Prop()
  public validCriterions!: PendingCriterionData[];
  @Prop()
  public missingLinkedBprCriterions!: PendingCriterionData[];
  @Prop()
  public newlyAddedCriterions!: PendingCriterionData[];

  get existingValidCriterions() {
    return this.existingCriterions.map((criterion) => {
      return {
        ...criterion,
        isValid: true
      };
    });
  }

  get proxyCriterions() {
    return this.dirtyCriterions;
  }

  set proxyCriterions(criterions: PendingCriterionData[]) {
    this.$emit('update:criterions', criterions);
  }

  get criterionErrorMessages() {
    const errorMessages = [];
    if (this.invalidCriterions.length > 0) {
      errorMessages.push({
        message: 'Incomplete Audit Criteria(s) found.'
      });
    }
    if (this.missingLinkedBprCriterions.length > 0) {
      errorMessages.push({
        message:
          'Audit Criteria(s) with missing linked best practice recommendation(s) found.'
      });
    }
    return errorMessages;
  }

  get criterionNoticeMessages() {
    const noticeMessages = [];
    if (this.newlyAddedCriterions.length > 0) {
      noticeMessages.push({
        message: 'New Audit Criteria(s) found.'
      });
    }
    return noticeMessages;
  }

  get criterionSectionTitle() {
    return textToOps('Audit Criteria');
  }

  get subSections(): FullDocumentRevisionObject['revision']['sections']['bprSection'] {
    return this.dirtyBprs;
  }

  public isMissingLinkedBpr(criterion: PendingCriterionData) {
    return (
      this.isValidCriterion(criterion) &&
      !validateExistingCriterionLinkedBpr(
        criterion as FullDocumentRevisionObject['revision']['sections']['criterionSection'][0],
        this.dirtyBprs
      )
    );
  }

  public isNewCriterion(criterion: PendingCriterionData) {
    return this.newlyAddedCriterions.includes(criterion);
  }

  public isValidCriterion(criterion: PendingCriterionData) {
    return criterion.isValid;
  }

  public openEditModal() {
    this.$buefy.modal.open({
      parent: this,
      component: StagingCriterionForm,
      hasModalCard: true,
      fullScreen: true,
      canCancel: true,
      trapFocus: true,
      props: {
        existingCriterions: this.existingValidCriterions,
        existingBprs: this.existingBprs,
        validCriterions: this.validCriterions,
        invalidCriterions: this.invalidCriterions,
        newlyAddedCriterions: this.newlyAddedCriterions,
        dirtyCriterions: this.dirtyCriterions,
        dirtyBprs: this.dirtyBprs,
        dirtyTagMaps: this.dirtyTagMaps
      },
      events: {
        'update:criterions': (
          e: FullDocumentRevisionObject['revision']['sections']['criterionSection']
        ) => this.$emit('update:criterions', e),
        'update:dirtyTagMaps': (e: DirtyTagMap[]) =>
          this.$emit('update:dirtyTagMaps', e)
      }
    });
  }
}
